import { useState, useEffect } from 'react';

export function useConnect() {
  const [isGlobalMessageOpen, setIsGlobalMessageOpen] = useState(true);

  const handleGlobalMessage = () => {
    sessionStorage.setItem('globalMessage', false);
    setIsGlobalMessageOpen(false);
  };

  const getGlobalMessage = () => {
    if (sessionStorage.getItem('globalMessage') === 'false') {
      setIsGlobalMessageOpen(false);
    } else {
      setIsGlobalMessageOpen(true);
    }
  };
  useEffect(() => {
    getGlobalMessage();
  }, []);

  return {
    isGlobalMessageOpen,
    handleGlobalMessage,
  };
}
