import { useUser } from '@kpr/store/User';
import { useConfig } from '@kpr/utils/config';
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';
import '@reach/skip-nav/styles.css';
import cx from 'classnames';
import { Helmet } from 'react-helmet-async';

import { PageDataProvider } from './context';
import Footer from './Footer';
import Header from './Header';
import Page from './styles';

export default function PageComponent({
  children,
  context = {},
  isShaded,
  metaDesc,
  title,
  isLandingPage = false,
  isPlaceOrderPage = false,
  isBrowseProduct = false,
  offSeason = false,
  ...props
}) {
  const { applicationName, bannerTitle, nextdoorId } = useConfig();
  const user = useUser();

  return (
    <PageDataProvider value={context}>
      <Helmet>
        <html lang="en-US" />
        <title>{`${title} | ${bannerTitle} ${applicationName}`}</title>
        <meta name="description" content={metaDesc}></meta>
        <script type="text/javascript">
          {isLandingPage &&
            `
            (function(win, doc, sdk_url){  if(win.ndp) return;  var tr=win.ndp=function(){  tr.handleRequest? tr.handleRequest.apply(tr, arguments):tr.queue.push(arguments);};  tr.queue = [];  var s='script';  var new_script_section=doc.createElement(s);  new_script_section.async=!0;  new_script_section.src=sdk_url;  var insert_pos=doc.getElementsByTagName(s)[0];  insert_pos.parentNode.insertBefore(new_script_section, insert_pos);})(window, document, 'https://ads.nextdoor.com/public/pixel/ndp.js');
        
            ndp('init', '${nextdoorId}', {});
            ndp('track','PAGE_VIEW');
          `}
        </script>
        <script type="text/javascript">
          {isPlaceOrderPage &&
            `
            (function(win, doc, sdk_url){  if(win.ndp) return;  var tr=win.ndp=function(){  tr.handleRequest? tr.handleRequest.apply(tr, arguments):tr.queue.push(arguments);};  tr.queue = [];  var s='script';  var new_script_section=doc.createElement(s);  new_script_section.async=!0;  new_script_section.src=sdk_url;  var insert_pos=doc.getElementsByTagName(s)[0];  insert_pos.parentNode.insertBefore(new_script_section, insert_pos);})(window, document, 'https://ads.nextdoor.com/public/pixel/ndp.js');
            ndp('init', '${nextdoorId}', {})
            `}
        </script>
        <script type="text/javascript">
          {user.isAuthenticated &&
            `
            !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}
            (window,document,'script','https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '126589911409162');
            `}
        </script>
        <script type="text/javascript">
          {isBrowseProduct &&
            `
            //<![CDATA[
            _uzactions = window._uzactions || [];
            _uzactions.push(['_setID', '333B94D2E90CEC11AA70B5A3E0D8D811']);
            _uzactions.push(['_setSID', '95E53A71C20CEC11AA70B5A3E0D8D811']);
            _uzactions.push(['_start']);
            (function() {var uz = document.createElement('script'); uz.type = 'text/javascript'; uz.async = true; uz.charset = 'utf-8';uz.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn5.userzoom.com/trueintent/js/uz_til_us.js?cuid=CC2E862F6DCDE41180C90050569444FB';var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(uz, s);})();
            //]]>
            `}
        </script>
        <noscript>
          {isLandingPage &&
            `<img
            height="1"
            width="1"
            style="display:none"
            src="https://flask.nextdoor.com/pixel?pid=${nextdoorId}&ev=PAGE_VIEW&noscript=1"
          />`}
        </noscript>
        <noscript>
          {isPlaceOrderPage &&
            `<img
            height="1"
            width="1"
            style="display:none"
            src="https://flask.nextdoor.com/pixel?pid=${nextdoorId}&ev=CONVERSION&noscript=1"/>
          />`}
        </noscript>
        <noscript>
          {user.isAuthenticated &&
            `<img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=126589911409162&ev=PageView&noscript=1"/>
          />`}
        </noscript>
        <body className={cx({ 'bg-default-100': isShaded })} />
      </Helmet>
      <SkipNavLink />
      <Page {...props}>
        {!offSeason && (
          <Page.Header className={isLandingPage ? '' : 'mb-24'}>
            <Header isLandingPage={isLandingPage} />
          </Page.Header>
        )}
        <Page.Content>
          <SkipNavContent />
          {!isLandingPage && (
            <div className={!offSeason ? 'container' : ''}>
              <main>{children}</main>
            </div>
          )}
          {isLandingPage && <main>{children}</main>}
        </Page.Content>
        <Page.Footer>
          <Footer isLandingPage={isLandingPage} offSeason />
        </Page.Footer>
      </Page>
    </PageDataProvider>
  );
}
