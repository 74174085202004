import { media } from '@kpr/utils/media';
import { FormField } from 'kds-react';
import styled from 'styled-components';

import bannerImage from './assets/banner-image.png';
import quoteImage from './assets/quote.png';

export const CustomFormSearch = styled(FormField)`
  .kds-Input.kds-FormField-input--withIconButton {
    border-radius: 50px !important;
  }

  .kds-Button.kds-Button--tertiary.kds-Button--hasIconOnly.kds-FormField-iconButton {
    cursor: not-allowed !important;
    pointer-events: none !important;
  }
`;

export const BannerBox = styled.div`
  padding: 0 20px;
  background: url(${bannerImage}) no-repeat center bottom;
  height: 100%;
  width: 100%;
  background-size: auto 420px;
  ${media.mdDown} {
    background-size: auto 320px;
  }
  ${media.smDown} {
    background-size: auto 200px;
  }
`;

export const NewBannerBox = styled.div`
  padding: 0 20px;
  background: url(${bannerImage}) no-repeat center bottom;
  height: 100%;
  width: 100%;
  background-size: auto 420px;
  ${media.mdDown} {
    background-size: auto 320px;
  }
  ${media.smDown} {
    background-size: auto 175px;
  }
`;

export const BannerContent = styled.div`
  ${media.mdDown} {
    width: 100%;
    padding: 0 40px;
  }
  ${media.smDown} {
    padding: 0;
  }
`;

export const BannerTitle = styled.div`
  font-size: 56px;
  font-family: Nunito, -apple-system, BlinkMacSystemFont, Roboto, Segoe UI, system-ui, Helvetica Neue, Ubuntu,
    sans-serif;
  font-weight: bold;
  ${media.mdDown} {
    font-size: 33px;
  }
  ${media.smDown} {
    font-size: 32px;
  }
`;

export const BannerSubTitle = styled.div`
  font-size: 24px;
  line-height: 32px;
  ${media.mdDown} {
    font-size: 20px;
  }
`;

export const ThreeBoxBg = styled.div`
  &:before {
    position: absolute;
    left: 0;
    top: -10px;
    content: '';
    background: #fff;
    height: 90px;
    width: 100%;
    z-index: 5;
  }
`;

export const RightBorderSection = styled.div`
  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -45px;
    content: '';
    background: #fcfcfc;
    height: 90px;
    width: 1px;
    z-index: 5;
    ${media.smDown} {
      width: 90px;
      height: 1px;
      left: 50%;
      top: 0;
      margin-top: 0;
      margin-left: -45px;
    }
  }
  ${media.smDown} {
    margin-top: 40px;
  }
`;

export const QuoteIcon = styled.div`
  padding: 0 20px;
  background: url(${quoteImage}) no-repeat center bottom;
  background-size: 100% auto;
  height: 73px;
  width: 91px;
  top: -40px;
  left: 40px;
  ${media.mdDown} {
    background-size: 65% auto;
    top: -50px;
    left: 0px;
  }
  ${media.smDown} {
    left: 32px;
  }
`;
