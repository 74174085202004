import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import NonButtonBase from '../NonButtonBase';

export default function NavigationButton({ as = Link, ...props }) {
  return <NonButtonBase as={as} {...props} />;
}

NavigationButton.propTypes = {
  as: PropTypes.oneOf([Link, HashLink, 'a']),
};
