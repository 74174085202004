import { useConfig } from '@kpr/utils/config';
import useBannerUrl from '@kpr/utils/useBannerUrl';

export function useConnect() {
  const { bannerTitle } = useConfig();

  const aboutCompany =
    bannerTitle && bannerTitle === 'Fred Meyer' ? 'https://www.fredmeyer.com' : 'https://www.thekrogerco.com/';
  const waysToShop = useBannerUrl('/i/ways-to-shop');
  const community = useBannerUrl('/i/community');
  const termsAndConditions = useBannerUrl('/i/terms');
  const privacyPolicy = useBannerUrl('/i/privacy-policy');
  const helpUrl = useBannerUrl('/hc/help');
  const utilityLinks = [{ href: helpUrl, label: 'Help' }];
  const bannerUrl = '/';
  const contactUrl = useBannerUrl('/hc/help/contact-us');

  return {
    utilityLinks,
    contactUrl,
    aboutCompany,
    waysToShop,
    community,
    termsAndConditions,
    privacyPolicy,
    bannerUrl,
  };
}
