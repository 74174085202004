import fredmeyerLogo from '../assets/fred_logo.svg';
// import krogerLogo from '../assets/logo.svg';
import qfcLogo from '../assets/qfc_logo.svg';
import { useConfig } from '../config';
import KrogerMarketWagonLogo from './../assets/Kroger_logo_market_wagon.png';

export default function useBannerDetails() {
  const { bannerName } = useConfig();
  return {
    imageUrl: bannerName === 'qfc' ? qfcLogo : bannerName === 'fredmeyer' ? fredmeyerLogo : KrogerMarketWagonLogo,
    width: bannerName === 'qfc' ? '150' : bannerName === 'fredmeyer' ? '200' : '200',
    height: bannerName === 'qfc' ? '150' : bannerName === 'fredmeyer' ? '43' : '88',
  };
}
