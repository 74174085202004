import styled from 'styled-components';

import Content from './Content';
import Overlay from './Overlay';

const Styled = styled.div.attrs({ className: 'block pin fixed page-modals' })`
  transition: visibility 0s;
  transition-delay: 0.2s;
  visibility: hidden;

  ${(props) =>
    props.$isOpen &&
    `
    transition-delay: 0s;
    visibility: visible;

    ${Content} {
      transform: translateX(0);
    }

    ${Overlay} {
      opacity: 0.7;
    }
  `}
`;

Styled.Content = Content;
Styled.Overlay = Overlay;

export default Styled;
