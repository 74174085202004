import { media } from '@kpr/utils/media';
import styled from 'styled-components';

export default styled.div.attrs({ className: 'border-brand-800' })`
  border-bottom: 4px solid;

  ${media.smUp} {
    height: 3.5em;
  }
`;
