import { Media } from '@kpr/utils/media';
import cx from 'classnames';
import { Text } from 'kds-react';

import NavigationButton from '../../../NavigationButton';

const actionClasses = 'flex flex-col h-auto items-center min-w-0 px-4 py-8 rounded-8 text-default-800 shadow-0';

function Action({ children, className, as, ...props }) {
  return (
    <NavigationButton as={as} className={cx(actionClasses, className)} kind="tertiary" {...props}>
      {children}
    </NavigationButton>
  );
}

function ActionIcon({ as: Icon }) {
  return <Icon size="l" />;
}

function ActionLabel({ children, className, ...props }) {
  return (
    <Media greaterThanOrEqual="lg">
      <Text size="m" className={cx('flex flex-row items-center mt-4', className)} {...props}>
        {children}
      </Text>
    </Media>
  );
}

export { Action, ActionIcon, ActionLabel, actionClasses };
