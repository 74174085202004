import { media } from '@kpr/utils/media';
import styled from 'styled-components';

export default styled.div.attrs({ className: 'flex flex-col items-center' })`
  ${media.smUp} {
    bottom: 40px;
    margin: auto;
    position: relative;
    width: 225px;
  }
`;
