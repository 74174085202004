import { useConfig } from '@kpr/utils/config';
import { Fragment } from 'react';

import { Media } from '../../../../utils/media';
import FooterLinks from '../FooterLinks';

const classes = {
  desktopSection: 'flex justify-end',
  desktopWrapper: 'flex lg:w-1/2  md:w-3/4 justify-end',
  mobileSection: 'max-w-full',
  mobileWrapper: 'w-full',
};

export default function FooterNav() {
  const { applicationName, bannerTitle } = useConfig();

  const sections = [
    { title: `${bannerTitle} ${applicationName}`, type: 'application' },
    { title: bannerTitle, type: 'banner' },
    { title: 'Keep in Touch', type: 'contact' },
  ];

  return (
    <Fragment>
      <Media className={classes.desktopWrapper} greaterThan="md">
        {sections.map(({ type }) => (
          <section className={classes.desktopSection} key={type}>
            <FooterLinks type={type} offSeason />
          </section>
        ))}
      </Media>
      <Media className={classes.mobileWrapper} lessThan="lg">
        <div className="flex flex-col justify-center items-center mb-32">
          <FooterLinks type="mobile" offSeason />
        </div>
      </Media>
    </Fragment>
  );
}
