import GlobalPromoMessage from '@kpr/components/GlobalPromoMessage';
import { Media } from '@kpr/utils/media';
import { Menu, MenuList, MenuButton } from '@reach/menu-button';
import '@reach/menu-button/styles.css';
import {
  Badge,
  Form,
  IconAccount,
  IconBasket,
  IconChevronDown,
  IconHamburgerMenu,
  Link,
  IconShip,
  Label,
  SolitarySearch,
} from 'kds-react';
import { Fragment } from 'react';

import { Action, ActionIcon, ActionLabel } from './Action';
import { useConnect } from './hooks';
import NavMenu from './NavMenu';
import SignInTooltippable from './SignInTooltippable';
import { StyledMenuButton, StyledMenuLink, StyledHeader } from './styles';

const classes = {
  main: 'flex flex-row items-center justify-between py-16 w-full',
};

// eslint-disable-next-line complexity
export default function Header({ isLandingPage = false }) {
  const {
    accountManagementUrl,
    applicationName,
    bannerTitle,
    bannerUrl,
    cartCount,
    handleSearchSubmit,
    handleNavMenuOpen,
    handleNavMenuClose,
    isLoggedIn,
    isNavMenuOpen,
    profileUrl,
    userFirstName,
    userZipCode,
    searchValue,
    onSearchChange,
    handleSignOutClick,
    imageUrl,
    width,
    height,
  } = useConnect();

  return (
    <Fragment>
      <GlobalPromoMessage />
      <StyledHeader boxshadow={!isLandingPage}>
        <div className="container">
          <section className={classes.main}>
            <div>
              <Link href={bannerUrl} withRouter>
                <img
                  loading="lazy"
                  src={imageUrl}
                  alt={`${bannerTitle} ${applicationName}`}
                  width={width}
                  height={height}
                />
              </Link>
            </div>
            {!isLandingPage && (
              <Media greaterThan="md" className="w-1/2">
                <Form className="flex-grow sm:mx-24 mx-8" onSubmit={handleSearchSubmit} role="search">
                  <SolitarySearch
                    aria-label="Product search"
                    value={searchValue}
                    onChange={onSearchChange}
                    placeholder="Search"
                    name="searchString"
                  />
                </Form>
              </Media>
            )}

            <section className="flex items-center">
              <Media greaterThanOrEqual="md">
                {isLoggedIn && (
                  <div className="flex items-center">
                    <IconShip size="l" />
                    <Label size="l" className="pl-12 pr-16">{`Deliver to ${userZipCode}`}</Label>
                  </div>
                )}
              </Media>
              {isLoggedIn ? (
                <Menu>
                  <MenuButton as={StyledMenuButton}>
                    <ActionIcon as={IconAccount} />
                    <ActionLabel>
                      <span>{userFirstName}</span>
                      <IconChevronDown className="ml-4" size="s" />
                    </ActionLabel>
                  </MenuButton>
                  <MenuList>
                    <StyledMenuLink target="_blank" rel="noopener noreferrer" href={profileUrl}>
                      My Profile
                    </StyledMenuLink>
                    <StyledMenuLink target="_blank" rel="noopener noreferrer" href={accountManagementUrl}>
                      Account Management
                    </StyledMenuLink>
                    <StyledMenuLink onClick={handleSignOutClick}>Sign Out</StyledMenuLink>
                  </MenuList>
                </Menu>
              ) : (
                <SignInTooltippable />
              )}
              {isLoggedIn && (
                <Fragment>
                  <Action to="/cart" aria-label="my cart">
                    <Badge count={cartCount}>
                      <ActionIcon as={IconBasket} />
                    </Badge>
                    <ActionLabel>My Cart</ActionLabel>
                  </Action>
                  <Media lessThan="lg">
                    <button aria-label="open menu" className="ml-12" onClick={handleNavMenuOpen}>
                      <IconHamburgerMenu size="xl" />
                    </button>
                    <NavMenu isOpen={isNavMenuOpen} onClose={handleNavMenuClose} />
                  </Media>
                </Fragment>
              )}
            </section>
          </section>

          {isLoggedIn && (
            <Media lessThan="md" className="mb-16">
              <div className="flex items-center justify-center">
                <IconShip size="l" />
                <Label size="l" className="pl-12 pr-16">{`Deliver to ${userZipCode}`}</Label>
              </div>
            </Media>
          )}

          {!isLandingPage && (
            <Media lessThan="lg" className="flex justify-center">
              <Form className="w-full" onSubmit={handleSearchSubmit} role="search">
                <SolitarySearch
                  aria-label="Product search"
                  value={searchValue}
                  onChange={onSearchChange}
                  placeholder="Search"
                  name="searchString"
                  className="justify-center"
                />
              </Form>
            </Media>
          )}
        </div>
      </StyledHeader>
    </Fragment>
  );
}
