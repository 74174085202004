import { media } from '@kpr/utils/media';
import styled from 'styled-components';

const Content = styled.div.attrs({ className: 'flex flex-col flex-1' })`
  grid-area: content;
`;

const Sidebar = styled.aside`
  grid-area: sidebar;
`;

const Header = styled.div`
  grid-area: header;
`;

const Footer = styled.div`
  grid-area: footer;
`;

const Styled = styled.div.attrs({ className: 'flex flex-col min-h-screen justify-between' })`
  ${(props) =>
    props.$hasCart &&
    `
    ${media.lgUp} {
      grid-template-areas:
        'header  header'
        'content sidebar'
        'footer  sidebar';
      grid-template-columns: 1fr 70px;
    }
  `}

  ${Sidebar} {
    ${media.lgDown} {
      display: none;
    }

    ${(props) =>
      !props.$hasCart &&
      `
      display: none;
    `}
  }
`;

Styled.Content = Content;
Styled.Header = Header;
Styled.Sidebar = Sidebar;
Styled.Footer = Footer;

export default Styled;
