import PropTypes from 'prop-types';

import NonButtonBase from './styles';

export default function NonButtonBaseComponent({ compact, kind = 'primary', ...props }) {
  return <NonButtonBase $kind={kind} $isCompact={compact} {...props} />;
}

NonButtonBaseComponent.propTypes = {
  kind: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'disabled']), // Feel free to add more, but only these 3 currently have styles
};
