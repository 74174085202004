import { GlobalMessage, Heading } from 'kds-react';
import { Fragment } from 'react';

import { useConnect } from './hooks';
export default function GlobalPromoMessage() {
  const { isGlobalMessageOpen, handleGlobalMessage } = useConnect();
  return (
    <Fragment>
      {isGlobalMessageOpen && (
        <GlobalMessage className="topGlobalMessage" onClose={handleGlobalMessage}>
          <Heading tag="h4" size="m">
            Enjoy $15 Off your First Order with code FRESH15 at checkout!
          </Heading>
          Delivery is FREE on all orders.
        </GlobalMessage>
      )}
    </Fragment>
  );
}
