import { signOut } from '@kpr/api/CustomersApi';
import { useCartCount } from '@kpr/store/Cart';
import { useUser, useUserUpdater } from '@kpr/store/User';
import { useConfig } from '@kpr/utils/config';
import useBannerDetails from '@kpr/utils/useBannerDetails';
import useBannerUrl from '@kpr/utils/useBannerUrl';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import get from 'lodash.get';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useConnect() {
  const { applicationName, bannerTitle } = useConfig();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { imageUrl, width, height } = useBannerDetails();
  const user = useUser();
  const updateUser = useUserUpdater();
  const cartCount = useCartCount();
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(queryString.parse(search).search || '');

  const bannerUrl = user.isAuthenticated ? '/browse' : '/';
  const accountManagementUrl = useBannerUrl('/account/addresses');
  const profileUrl = useBannerUrl('/account/update');
  const params = queryString.parse(search);

  const handleSearchSubmit = ({ searchString = '' }) => {
    if (searchString.trim().length > 0) {
      setSearchValue(searchString);
      if ((pathname === '/browse' && params.tab === 'seller') || pathname.includes('/seller')) {
        history.push({
          pathname: pathname,
          search: queryString.stringify({
            ...queryString.parse(search),
            search: searchString,
            page: 1,
          }),
        });
      } else {
        history.push({
          pathname: '/browse',
          search: queryString.stringify({
            ...queryString.parse(search),
            search: searchString,
            tab: 'product',
            page: 1,
            trending: false,
            categories: undefined,
            subCategories: undefined,
          }),
        });
      }
    } else {
      setSearchValue('');
      if (pathname === '/browse' || pathname.includes('/seller')) {
        history.push({
          pathname: pathname,
          search: queryString.stringify({
            ...queryString.parse(search),
            search: undefined,
            page: 1,
          }),
        });
      }
    }
  };

  const handleNavMenuOpen = () => {
    disableBodyScroll(true);
    setIsNavMenuOpen(true);
  };

  const handleNavMenuClose = () => {
    enableBodyScroll(true);
    setIsNavMenuOpen(false);
  };

  const onSearchChange = (event) => {
    setSearchValue(event.target.value);
    if (queryString.parse(search).search && event.target.value === '') {
      history.push({
        pathname: pathname,
        search: queryString.stringify({
          ...queryString.parse(search),
          search: undefined,
          page: 1,
          trending: true,
          categories: undefined,
          subCategories: undefined,
        }),
      });
    }
  };

  const handleSignOutClick = async () => {
    await signOut();

    updateUser(() => {
      localStorage.clear();
      history.push({
        pathname: '/',
      });
    });
  };

  useEffect(() => {
    setSearchValue(queryString.parse(search).search || '');
  }, [search]);

  return {
    accountManagementUrl,
    applicationName,
    bannerTitle,
    bannerUrl,
    cartCount: cartCount || undefined,
    handleSearchSubmit,
    handleNavMenuClose,
    handleNavMenuOpen,
    isLoggedIn: user.isAuthenticated,
    isNavMenuOpen,
    profileUrl,
    userFirstName: user.firstName,
    userZipCode: get(user, 'deliveryAddress.zipCode'),
    searchValue,
    onSearchChange,
    handleSignOutClick,
    imageUrl,
    width,
    height,
  };
}
