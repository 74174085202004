import { useSignInUrl } from '@kpr/api/CustomersApi';
import { Button, IconAccount, IconClose, Link, Text } from 'kds-react';

import { useConnect } from './hooks';
import NavMenu from './styles';

const classes = {
  navLink: 'block pl-48 py-12',
  utilityHeader: 'bg-default-50 pt-8 h-56 px-8 sm:pin-none sm:static sticky pin-t',
  welcomeText: 'my-8 px-8 text-center truncate w-full',
};

export default function NavMenuComponent({ isOpen, onClose }) {
  const { isLoggedIn, userFirstName, utilityLinks } = useConnect();
  const signInUrl = useSignInUrl();

  return (
    <NavMenu $isOpen={isOpen}>
      <NavMenu.Overlay onClick={onClose} />
      <NavMenu.Content>
        <div className={classes.utilityHeader}>
          <Button
            aria-label="navigation menu"
            aria-expanded="true"
            className="float-right"
            kind="tertiary"
            onClick={onClose}
          >
            <IconClose />
          </Button>
        </div>
        <NavMenu.Content.Header>
          <NavMenu.Content.Welcome>
            <IconAccount className="bg-default-200 rounded-full" size="xl" />
            {isLoggedIn ? (
              <Text className={classes.welcomeText}>{userFirstName}</Text>
            ) : (
              <Link className={classes.welcomeText} implied href={signInUrl} withRouter={false}>
                Hi! Want to sign in?
              </Link>
            )}
          </NavMenu.Content.Welcome>
        </NavMenu.Content.Header>
        <div className="py-12">
          {utilityLinks.map((link) => (
            <Link
              implied
              className={classes.navLink}
              href={link.href}
              key={link.label}
              withRouter
              onClick={onClose}
            >
              {link.label}
            </Link>
          ))}
        </div>
      </NavMenu.Content>
    </NavMenu>
  );
}
