import { MenuLink } from '@reach/menu-button';
import cx from 'classnames';
import styled from 'styled-components';

import { actionClasses } from './Action';

// MenuLink has an `as` prop that we can use.
// You may be tempted to set that to KdsLink and add an `implied` attribute instead,
// but that doesn't work because KdsLink doesn't leverage forwardRef. :[
export const StyledMenuLink = styled(MenuLink)`
  &[data-reach-menu-item] {
    color: #1d1e1f;

    &[data-selected] {
      background-color: inherit;
      color: inherit;
      text-decoration: underline;
    }
  }
`;

// the KDS default button transformation size change shifts the menu in an odd way
// it makes sense to disable this particular interaction here
export const StyledMenuButton = styled.button.attrs({
  className: cx(actionClasses, 'kds-Button kds-Button--tertiary'),
})`
  &.kds-Button:enabled:active {
    transform: none;
  }
`;

export const StyledHeader = styled.header.attrs({})`
  .kds-Tooltip-arrow {
    border: none !important;
  }
  ${({ boxshadow }) =>
    boxshadow
      ? `
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.17);
    padding: 10px 0;
    background:#ffffff;
  `
      : `background:#f2f2f2`}
`;
