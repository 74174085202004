import { Link } from 'kds-react';

import { useConnect } from './hooks';

const classes = {
  listItem: 'mb-16',
};

export default function FooterLinks({ type }) {
  const {
    utilityLinks,
    contactUrl,
    aboutCompany,
    waysToShop,
    community,
    termsAndConditions,
    privacyPolicy,
  } = useConnect();

  switch (type) {
    case 'application':
      return (
        <ul className="list-reset">
          <li className={classes.listItem}>
            <Link
              className="text-default-700"
              implied
              href={aboutCompany}
              withRouter={false}
              target="_blank"
              rel="noopener noreferrer"
            >
              About the Company
            </Link>
          </li>
          <li className={classes.listItem}>
            <Link
              className="text-default-700"
              implied
              href={waysToShop}
              withRouter={false}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ways to Shop
            </Link>
          </li>
          <li className={classes.listItem}>
            <Link
              className="text-default-700"
              implied
              href={community}
              withRouter={false}
              target="_blank"
              rel="noopener noreferrer"
            >
              Community
            </Link>
          </li>
        </ul>
      );
    case 'banner':
      return (
        <ul className="list-reset ml-80">
          <li className={classes.listItem}>
            <Link
              className="text-default-700"
              implied
              href={termsAndConditions}
              withRouter={false}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </Link>
          </li>
          <li className={classes.listItem}>
            <Link
              className="text-default-700"
              implied
              href={privacyPolicy}
              withRouter={false}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
          </li>
          {utilityLinks.map((link) => (
            <li className={classes.listItem} key={link.label}>
              <Link className="text-default-700" implied href={link.href} target="_blank">
                {link.label}
              </Link>
            </li>
          ))}
        </ul>
      );
    case 'contact':
      return (
        <ul className="list-reset ml-40">
          <li className={classes.listItem}>
            <Link className="text-default-700" implied href={contactUrl} withRouter={false} target="_blank">
              Contact
            </Link>
          </li>
        </ul>
      );
    case 'mobile':
      return (
        <ul className="list-reset">
          <li className={classes.listItem}>
            <Link
              className="text-default-700"
              implied
              href={aboutCompany}
              withRouter={false}
              target="_blank"
              rel="noopener noreferrer"
            >
              About the Company
            </Link>
          </li>
          <li className={classes.listItem}>
            <Link
              className="text-default-700"
              implied
              href={waysToShop}
              withRouter={false}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ways to Shop
            </Link>
          </li>
          <li className={classes.listItem}>
            <Link
              className="text-default-700"
              implied
              href={community}
              withRouter={false}
              target="_blank"
              rel="noopener noreferrer"
            >
              Community
            </Link>
          </li>
          <li className={classes.listItem}>
            <Link
              className="text-default-700"
              implied
              href={termsAndConditions}
              withRouter={false}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </Link>
          </li>
          <li className={classes.listItem}>
            <Link
              className="text-default-700"
              implied
              href={privacyPolicy}
              withRouter={false}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
          </li>
          {utilityLinks.map((link) => (
            <li className={classes.listItem} key={link.label}>
              <Link className="text-default-700" implied href={link.href} withRouter target="_blank">
                {link.label}
              </Link>
            </li>
          ))}

          <li className={classes.listItem}>
            <Link className="text-default-700" implied href={contactUrl} withRouter={false} target="_blank">
              Contact
            </Link>
          </li>
        </ul>
      );
    default:
      return null;
  }
}
