import { useSignInUrl, useSignUpUrl } from '@kpr/api/CustomersApi';
import NavigationButton from '@kpr/components/NavigationButton';
import { IconAccount, IconChevronDown } from 'kds-react';

import { CustomTooltippable } from './styles';

export default () => {
  const signInUrl = useSignInUrl();
  const signUpUrl = useSignUpUrl();

  return (
    <CustomTooltippable
      tooltipText={
        <div>
          <NavigationButton as="a" className="w-full mb-8" href={signInUrl}>
            Sign In
          </NavigationButton>
          <NavigationButton as="a" className="w-full mb-8" kind="secondary" href={signUpUrl}>
            Create Account
          </NavigationButton>
        </div>
      }
      side="bottom"
    >
      <div className="text-center">
        <div className="flex items-end justify-end">
          <div>
            <div>
              <IconAccount />
            </div>
            <span className="cursor" role="button" aria-label="sign in" tabIndex={-1}>
              Sign In
            </span>
          </div>
          <div>
            <IconChevronDown />
          </div>
        </div>
      </div>
    </CustomTooltippable>
  );
};
