import Page from '@kpr/components/Page';
import { Button, Link } from 'kds-react';
import React from 'react';

import { useConnect } from './AvailibiityCheck/hooks';
import style from './style.module.css';
import { BannerTitle, BannerSubTitle, BannerContent, NewBannerBox } from './styles';

export default () => {
  const {
    bannerTitle,
    applicationName,
    bannerName,
    imageUrl,
    width,
    height,
    marketWargonLink,
    title,
  } = useConnect();

  return (
    <Page title={title} offSeason>
      {/* Banner Section */}
      <div
        className={`${style.newBannerImage} ${
          bannerName === 'qfc' ? style.qfcLogo : bannerName === 'kroger' ? style.marketWagonLogo : ''
        }`}
      >
        <NewBannerBox>
          <div className="text-center pt-32">
            <img
              loading="lazy"
              src={imageUrl}
              alt={`${bannerTitle} ${applicationName}`}
              width={width}
              height={height}
            />
          </div>
          {bannerTitle === 'Kroger' ? (
            <BannerContent className="mx-auto text-center md:pt-72 pt-32">
              <BannerTitle className="mb-24">
                Supporting Local.
                <br className="sm:hidden block" /> Delivering Fresh.
              </BannerTitle>
              <BannerSubTitle className="font-normal mb-32 robotoFont">
                Introducing Kroger Farmers Market powered by Market Wagon! Exclusively{' '}
                <br className="md:block hidden" />
                serving Atlanta, this all-new partnership service delivers more than 1,150 local{' '}
                <br className="md:block hidden" />
                items across a 60-mile radius.
              </BannerSubTitle>
              <Link implied href={marketWargonLink} withRouter={false} target="_blank">
                <Button kind="primary" className="px-40 py-24">
                  Visit Market Wagon{' '}
                </Button>
              </Link>
            </BannerContent>
          ) : (
            <BannerContent className="mx-auto text-center md:pt-72 pt-32">
              <BannerTitle className="mb-24">Thank You!</BannerTitle>
              <BannerSubTitle className="font-normal mb-32 robotoFont">
                Our online Farmer's Market is no longer in business. Along with our local{' '}
                <br className="md:block hidden" />
                farmers, artisans and bakers, we want to thank you for your interest.
              </BannerSubTitle>
            </BannerContent>
          )}
        </NewBannerBox>
      </div>
    </Page>
  );
};
