import { media } from '@kpr/utils/media';
import styled from 'styled-components';

import Header from './Header';
import Welcome from './Welcome';

const Styled = styled.div.attrs({
  className: 'bg-default-50 fixed overflow-y-auto overflow-x-hidden pb-64',
})`
  height: 100%;
  transform: translateX(-100%);
  transition: transform 0.2s;
  width: 100%;

  ${media.smUp} {
    width: 365px;
  }
`;

Styled.Header = Header;
Styled.Welcome = Welcome;

export default Styled;
