import { Tooltippable } from 'kds-react';
import styled from 'styled-components';

export const CustomTooltippable = styled(Tooltippable)`
  .kds-Tooltip {
    right: 0 !important;
    left: auto !important;
    transform: none !important;
  }
  .kds-Tooltip-arrow {
    color: #fff;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      left: 44px;
      top: 10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 11px 11px 11px;
      z-index: 2;
      border-color: transparent transparent #f8f9f9 transparent;
    }
    &:after {
      position: absolute;
      content: '';
      left: 44px;
      top: 9px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 11px 11px 11px;
      z-index: 1;
      border-color: transparent transparent #d5d5d5 transparent;
    }
  }
  .kds-Tooltip-content {
    background-color: #f8f9f9;
    border: 1px solid #d5d5d5;
    position: relative;
    margin-top: 8px;
    z-index: 0;
  }
`;
