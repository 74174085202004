import { checkAvailability } from '@kpr/api/CustomersApi';
import { useUser } from '@kpr/store/User';
import { useConfig } from '@kpr/utils/config';
import useBannerDetails from '@kpr/utils/useBannerDetails';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export function useConnect() {
  const history = useHistory();
  const user = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const { bannerName, bannerTitle, applicationName } = useConfig();
  const { imageUrl, width, height } = useBannerDetails();
  const bannerUrl = user.isAuthenticated ? '/browse' : '/';
  const marketWargonLink = 'https://marketwagon.com/kroger/?utm_source=kroger&utm_campaign=krogerlp';
  const title = bannerName === 'kroger' ? 'Supporting Local. Delivering Fresh. ' : 'OffSeason Page';

  const onSubmitZipCode = async (data) => {
    try {
      const response = await checkAvailability(data);
      localStorage.setItem('deliveryCenter', response.deliveryCenter.id);
      setIsOpen(true);
    } catch (ex) {
      localStorage.setItem('waitListedZipCode', data.ZipCode);
      history.push('/waitlist');
    }
  };

  const onSubmitEmail = () => {
    // console.log(data);
  };

  useEffect(() => {
    if (user.isAuthenticated) {
      history.push('/browse');
    }
  }, [history, user]);

  return {
    imageUrl,
    width,
    height,
    bannerTitle,
    bannerName,
    isOpen,
    setIsOpen,
    history,
    onSubmitZipCode,
    onSubmitEmail,
    applicationName,
    bannerUrl,
    marketWargonLink,
    title,
  };
}
