import { Media } from '@kpr/utils';
import { useConfig } from '@kpr/utils/config';
import useBannerDetails from '@kpr/utils/useBannerDetails';
import { Paragraph } from 'kds-react';

// import { useConnect } from './FooterLinks/hooks';
import FooterNav from './FooterNav/FooterNav';

const classes = {
  copyright: 'border-t border-default-300 pt-24 mt-24',
  logoCol: 'mb-24 lg:w-1/2 md:w-1/4  md:text-left text-center ',
  wrapper: 'bg-default-100 border-t border-default-300 py-24 md:py-40 ',
  container: 'container',
  topFooter: 'md:flex items-center',
};

export default function Footer({ isLandingPage = false, offSeason = false }) {
  const { applicationName, bannerTitle } = useConfig();
  // const { bannerUrl } = useConnect();
  const { imageUrl, width, height } = useBannerDetails();
  return (
    <footer className={`${classes.wrapper} ${isLandingPage || offSeason ? '' : 'mt-40'}`}>
      <div className={classes.container}>
        <div className={classes.topFooter}>
          <Media className={classes.logoCol} greaterThanOrEqual="lg">
            <div>
              {/* <Link href={bannerUrl} withRouter> */}
              <img src={imageUrl} alt={`${bannerTitle} ${applicationName}`} height={height} width={width} />
              {/* </Link> */}
            </div>
          </Media>
          <FooterNav offSeason />
          <Media className={classes.mobileWrapper} lessThan="lg">
            <div className={classes.logoCol}>
              {/* <Link href={bannerUrl} withRouter> */}
              <img src={imageUrl} alt={`${bannerTitle} ${applicationName}`} height={height} width={width} />
              {/* </Link> */}
            </div>
          </Media>
        </div>
      </div>
      <div className={classes.copyright}>
        <div className={classes.container}>
          <Paragraph className="max-w-full md:text-right text-center text-default-600 mb-0" size="m">
            All Contents &copy;{new Date().getFullYear()} The Kroger Co. All Rights Reserved
          </Paragraph>
        </div>
      </div>
    </footer>
  );
}
