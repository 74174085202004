import { useUser } from '@kpr/store/User';

export function useConnect() {
  const user = useUser();
  const utilityLinks = [{ href: '/FAQ', label: 'Help' }];

  return {
    isLoggedIn: user.isAuthenticated,
    userFirstName: user.firstName,
    utilityLinks,
  };
}
