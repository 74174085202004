import cx from 'classnames';
import styled, { css } from 'styled-components';

// Within KDS, :hover and :active states are styled with the :enabled pseudo-selector.
// As such, we can't use them on non-buttons, and have to re-create them below.
export default styled.span.attrs((props) => ({
  className: cx('kds-Button no-underline', `kds-Button--${props.$kind}`, {
    'kds-Button--compact': props.$isCompact,
  }),
}))`
  cursor: default;

  &:active {
    transform: scale(0.96);
  }

  &:focus-within {
    outline: 1px auto Highlight;
    outline: -webkit-focus-ring-color auto 1px;
    position: relative;
    z-index: 1;
  }

  ${(props) =>
    props.$kind === 'disabled' &&
    css`
      cursor: not-allowed !important;
      opacity: 0.4 !important;
    `}

  ${(props) =>
    props.$kind === 'primary' &&
    css`
      color: #fff !important;

      &:hover {
        background-color: #385397;
      }

      &:active {
        background-color: #385397;
        border-color: transparent;
        outline-color: #4471b7;
      }
    `}

  ${(props) =>
    props.$kind === 'secondary' &&
    css`
      &:hover {
        background-color: #eff0f1;
      }

      &:active {
        color: #385397;
        background-color: #dfe1e1;
        border-color: #385397;
        outline-color: #4471b7;
      }
    `}

    ${(props) =>
    props.$kind === 'tertiary' &&
    css`
      &:hover {
        background-color: #eff0f1;
      }

      &:active {
        color: #385397;
        background-color: #dfe1e1;
        outline-color: #4471b7;
      }
    `}
`;
